.mobile__menu--open {
    overflow: hidden;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}
.navbar__links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.navbar__links-logo {
    margin-right: 2rem;
}
.navbar__links-logo > img {
    width: 90px;
}
.navbar__links-container {
    --margin-between-menu-links: 10px;
    --menu-link-size: 60px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    height: 60px;
}
.navbar__links-container p,
.navbar__mobile-container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    -webkit-transition: .7s;
	  -moz-transition: .7s;
	  -o-transition: .7s;
	  -ms-transition: .7s;
	  transition: .7s;

    width: var(--menu-link-size);
    text-align: center;
    margin: 0 var(--margin-between-menu-links);
    cursor: pointer;
}

.navbar__links-container p:hover {
    color: var(--color-core);
}
.mobile-menu-open body {
    overflow: hidden;
}


.navbar__mobile {
    margin-left: 1rem;
    display: none;
    position: relative;
    z-index: 13;
    
}

.navbar__mobile svg {
    cursor: pointer;
    color: var(--color-bright);
    -webkit-transition: .7s;
	  -moz-transition: .7s;
	  -o-transition: .7s;
	  -ms-transition: .7s;
	  transition: .7s;
}
.navbar__mobile svg:hover {
    color: var(--color-core);
}

.navbar__mobile-container {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-bg);
    padding: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    overflow: hidden;

}

.navbar__mobile-container > p {
    margin: 20px;
    font-size: 24px;
}
.navbar__mobile-container > p:hover {
    color: var(--color-core);
}

.navbar__links-container p:nth-child(1):hover ~ .navbar__effect {
    left: calc(10px + 0 * (var(--menu-link-size) + var(--margin-between-menu-links) * 2)); 
}  
.navbar__links-container p:nth-child(2):hover ~ .navbar__effect {
    left: calc(10px + 1 * (var(--menu-link-size) + var(--margin-between-menu-links) * 2));
}
.navbar__links-container p:nth-child(3):hover ~ .navbar__effect {
    left: calc(10px + 2 * (var(--menu-link-size) + var(--margin-between-menu-links) * 2));
}
.navbar__links-container p:nth-child(4):hover ~ .navbar__effect {
    left: calc(10px + 3 * (var(--menu-link-size) + var(--margin-between-menu-links) * 2));
}


.navbar__effect {
    position: absolute;
    left: -60px;
    width: 60px;
    height: 2px;
    top: 0px;
    background: var(--color-core);
    -webkit-transition: 0.7s ease-in-out;
    -moz-transition: 0.7s ease-in-out;
    -o-transition: 0.7s ease-in-out;
    -ms-transition: 0.7s ease-in-out;
    transition: 0.7s ease-in-out; 
    /* box-shadow: 0 1px 0 white;  */

  }


@media screen and (min-width: 1051px) {
    .navbar {
        position: fixed;
        width: 100%;
        z-index: 9999;
        
    }
    .navbar__shrink {
        top: 0;
        height: 120px;
        opacity: 0.98;
    }
    .navbar__links {
        -webkit-transition: all 7s ease-in-out;
        -moz-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out; 
    }
    .navbar__shrink .navbar__links {
        transform: scale(0.95);
    }
 
}
@media screen and (max-width: 1050px) {


    .navbar__mobile {
        display: flex;
    }

    .navbar__links-container {
        display: none;
    }
}
@media screen and (max-width: 700px) {
    .navbar {
        padding: 2rem 4rem;
    }

    .navbar__mobile-container {
        display: flex;
    }

}

@media screen and (max-width: 550px) {
    .navbar {
        padding: 2rem;
    }

    .navbar__mobile-container {
        top: 20px;
    }  
}
/* 
mobile inspiration
https://codepen.io/Kaneda9/pen/NpZGEK */