textarea {
    resize: none;
}
.heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}
.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.contact__body {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.contact__contact--container {
    margin-right: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact__message--status {
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
}
.contact__message--status.success {
    color: green;
}
.contact__message--status.bad {
    color: var(--color-danger);
}



@media screen and (min-width: 1051px) {
    .contact__contact {
        flex-direction: row;
    }
    .contact__contact--container {
        flex-direction: row;
        width: 80%;
        bottom: auto;
    }
    .contact__contact--image {
        justify-content: start;
    }
    .contact__body {
        flex-direction: row;
    }
}
.contact__contact--image {
    width: 15%;
    display: flex;
    
    margin: auto;
}
.contact__contact--image img {
    width: 100%;
    display: flex;
}
.contact__contact--col {
    flex-direction: column;
    display: flex;
    width: 100%;
}
.contact__recaptcha {
    margin: auto;
}

.input__container {
    margin: 15px;
}
.input__col2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.input__col2 > .input__container {
    width: 50%;
}


.checkbox {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-bright);
    accent-color: var(--color-core);
}
.checkbox__sign {
    margin-right: 10px;
}
.checkbox__label {
    font-family: var(--font-family);
}