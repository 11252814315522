@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Walter+Turncoat&display=swap');


:root {
  --font-family: 'Manrope', sans-serif;
  --font-family-walter: 'Walter Turncoat', cursive;
  --gradient-text: linear-gradient(89.97deg, #F8A826  1.84%, #b9b9b9 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #F8A826 -13.86%,#b9b9b9 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #c2acf8;
  --color-subtext: #FF8A71;
  --color-core: #F8A826;
  --color-bright: #fff;
  --color-danger: #F73466;

  --screen-md: 1050px;
  --screen-sm: 700px;
  --screen-xs: 550px;
}