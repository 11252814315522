.about-us {
    display: flex;
    flex-direction: column;
    
}
.about-us__body {
    display: flex;
    flex-direction: column;
}


.about-us__image {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%233989D8' d='M42.7,-50.1C53,-42.2,57.6,-26.6,55.7,-13.4C53.8,-0.2,45.5,10.6,38.1,20.7C30.8,30.8,24.4,40.1,12.8,51.3C1.2,62.5,-15.5,75.6,-27.9,72.8C-40.3,70,-48.4,51.3,-58.2,33.8C-67.9,16.4,-79.4,0.2,-76.8,-13.4C-74.2,-27,-57.7,-38,-42.5,-45.1C-27.4,-52.1,-13.7,-55.3,1.2,-56.7C16.2,-58.2,32.3,-58,42.7,-50.1Z' transform='translate(100 100)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 50% 50%;
    margin-bottom: 100px;
    
}

.about-us__image svg {
    width: 50%;
    height: 50%;
    transform: rotate(-15deg); 
}
.about-us__content {
    flex: 1;
    display: flex;
    color: var(--color-bright);
    flex-direction: column;
    font-family: var(--font-family);
    text-align: center;
}
.about-us__content h4 {
    color: var(--color-text);

}
.about-us__content h1 {
    font-weight: 900;
    font-size: 24px;
}

.about-us__list {
    color: var(--color-core);
    list-style-type: none;
}
.about-us__list span {
    color: var(--color-bright);
}


@media screen and (min-width: 1051px) {
    .about-us__body {
        flex-direction: row;
    }
    .about-us__image svg {
        width: 80%;
        height: 80%;
    }
    .about-us__image {
        background-size: 70%;
        margin-bottom: 0;
    }
    .about-us__content {
        text-align: justify;
    }

}