/* https://codepen.io/meodai/pen/rNedxBa */


  
.input {
    position: relative;
    --size-bezel: .75rem;
    --border-size: 1px;
    font-family: var(--font-family);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.input__field:focus {
    outline: none !important;
    border: var(--border-size) solid var(--color-core);
    color: var(--color-core);
  }

.input__label {

    position: absolute;
    color: var(--color-bright);
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: calc(100% - 20px);    
    width: calc(100% - 20px);    
    transform: translate(0, 0);
    transform-origin: 0 0;
    transition: transform 120ms ease-in;
    font-weight: regular;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    
}
.input__field {

    display: flex;
    width: 100%;
    border: var(--border-size) solid var(--color-bright);
    padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
    color: var(--color-bright);
    background: transparent;
}

.input__field:focus ~ .input__label, 
.input__field:not(:placeholder-shown) ~ .input__label {
    top: 0px;
    left: 0px;
    transform: translate(0px, -100%) scale(.8);
    width: 120%;
    color: var(--color-core);
    font-weight: bold;
}

.input__touched:invalid {
    border: var(--border-size) solid var(--color-danger);
}
.input__touched:invalid ~ .input__label {
    color: var(--color-danger);
}
    