.btn {
    width: 100%;
    border: 2px solid black;
    background-color: transparent;
    color: black;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    transition: all .5s ease;
}

.btn:disabled {
    background-color: gray;
    opacity: 0.7;
    pointer-events: none;
}
.btn-disabled {
    cursor: not-allowed;
}

.default {
    border-color: var(--color-bright);
    background: linear-gradient(to right,  transparent 50%, var(--color-bright) 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    color: var(--color-bright);
}

.default:hover {
    background-position: right bottom;
    color: var(--color-core)
}