@keyframes shake{
  0%{
    transform: translateX(0)
  }
  25%{
    transform: translateY(5px);
  }
    
  50%{
    transform: translateY(-5px);
  }
  100%{
    transform: translateX(0px);
  }
}
.feature__container {
  position: relative;
  --block-side: 250px;
  padding: 10px;
  max-width: var(--block-side);
  min-width: var(--block-side);
  height: var(--block-side);
  display: flex;
  margin: 1rem;
  border: var(--color-core) 2px solid;
  
  border-radius: 1%;
  transition: all 2s ease;
  -webkit-animation: scale-up-center 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.feature__container--effect {    
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 90%;
  width: 246px;
  height: 246px;
  transform: scale(0);
  transition: all 450ms ease 0ms;
  background-color: var(--color-bright);
}
.feature__container:hover > .feature__container--effect {
  transform: scale(1);
  border-radius: 1%;
}
.feature__container:hover > .feature__container--main {
  transition: all 450ms ease 0ms;
  color: var(--color-core);
  animation:  shake 0.5s  ;
}
.feature__container:hover > .feature__container--main .feature__title p {
  transform: scale(1);
  -webkit-animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.feature__container--main {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--color-bright);
}
.feature__title {
  display: flex;
  flex: 1;
  margin-top: 30px;
  flex-direction: column;
  text-align: center;
}
.feature__icon {
  margin-top: 20px;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}
.feature__icon svg {
  font-size: 50px;
}

.feature__title h1 {
  font-family: var(--font-family);
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.feature__title p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  transform: scale(0);
  text-transform: uppercase;
  letter-spacing: -.3;
}
.feature__title--bar {
  width: 100%;
  height: 3px;
  background: var(--color-core);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
}


