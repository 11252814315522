
.slick-track .slick-slide {
    display: flex !important;
    height: 60px;
    width: 120px;
    align-items: center;
    justify-content: center;
}

.brand__image img {
    max-width: 120px;
    max-height: 60px;
    filter: brightness(0) invert(1);
}