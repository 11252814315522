* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

p {
    font-family: var(--font-family);
}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}
.content__container {
    padding-top: 150px;
}

.section__header {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.section__header h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}
.gradient__bg {

    /* ff 3.6+ */
    background:-moz-linear-gradient(90deg, rgba(27, 28, 29, 1) 0%, rgba(0, 0, 0, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(27, 28, 29, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(27, 28, 29, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#1B1C1D', GradientType=0 );

    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(27, 28, 29, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(27, 28, 29, 1) 0%, rgba(0, 0, 0, 1) 100%);


}

.gradient__text {
    background: var(--color-core);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.cookie__button {
    background-color: var(--color-core);
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
@media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
}