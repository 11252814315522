.offer {
    display: flex;

}

.offer__features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 1051px) {
    .offer__features {
        margin: auto;
        max-width: 1200px;
    
    }
}