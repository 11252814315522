
   
.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}



.footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;

    margin-bottom: 10rem;
    cursor: pointer;
}

.footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

.footer__links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
}

.footer__links div {
    width: 250px;
    margin: 1rem;
}

.footer__links_logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__links_logo img {
    width: 118px;

    margin-bottom: 1rem;
}

.footer__links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.footer__links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.footer__links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.footer__links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .footer__links div {
        margin: 1rem 0;
    }

    .footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}


.social-container {
    text-align: center;

    --timing: 265ms;
    --iconColor: #00B5F5;
    --accent: #002A8F;
    --bluefade: #0043E0;
    --gradient: #00B5F5;
}

.social-icons {
    padding: 0;
    list-style: none;
    margin: 1em;
}
.social-icons li {
        display: inline-block;
        margin: 0.15em;
        position: relative;
        font-size: 1.2em;

    }

    .social-icons .footer__social--icon {
        color: var(--color-bright);
        position: absolute;
        top: 21px;
        left: 21px;
        transition: all var(--timing) ease-out;
    }

    .social-icons a {
        display: inline-block;
      
        
    }
    .social-icons a::before {
        transform: scale(1);
         -ms-transform: scale(1);
        -webkit-transform: scale(1);
        content: " ";
        width: 60px;
        height: 60px;
        border-radius: 100%;
        display: block;
        /* background: linear-gradient(45deg, var(--iconColor), var(--accent)); */
        background-color: var(--color-core);
        transition: all var(--timing) ease-out;
    }
    
    .social-icons a:hover::before {
        transform: scale(0);
        transition: all var(--timing) ease-in;
    }
    
    .social-icons a:hover .footer__social--icon {
        transform: scale(2.2);
        -ms-transform: scale(2.2);
        -webkit-transform: scale(2.2);
        color: var(--color-core);
        background: -webkit-linear-gradient(45deg, var(--iconColor), var(--accent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: all var(--timing) ease-in;
    }


    @media screen and (min-width: 1051px) {
        .footer__links {
            justify-content: space-between;
            align-items: flex-start;
            text-align: left;
        }
        .footer__links_logo {
            align-items: flex-start;
        }

    }

