.header {
    display: flex;
    flex-direction: row;
    z-index: 10;
    position: relative;
}

.header__content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.header__content h1 {
    font-family: var(--font-family);
    color: var(--color-core);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    z-index: 1;
}

.header__content p {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-bright);
    z-index: 1;
    margin-top: 1.5rem;
}


.header__image {
    flex: .5;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.header__image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .header {
        flex-direction: column;
    }

    .header__content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .header__content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .header__content p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .header__content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .header__content p {
        font-size: 14px;
        line-height: 24px;
    }
}

.typed::after {
	content: '|';
	display: inline;
	-webkit-animation: blink 0.7s infinite;
	-moz-animation: blink 0.7s infinite;
	animation: blink 0.7s infinite;
    z-index: 1;
}
.ityped-cursor {
    opacity: 0;
	display: none;
}


/* .ityped-cursor {
    opacity: 1;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
    animation-direction: alternate;
} */

/*Custom cursor animation*/
@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
.particles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}

/* .particles canvas {
    display: block;
    position: absolute !important;


} */

canvas {
    display: block;
    vertical-align: bottom;
    position: absolute !important;
  }